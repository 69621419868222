@import url("https://fonts.googleapis.com/css?family=Chivo");

.loading{
    display: flex;
    align-items: center;
    justify-content: center;
}

.centerLoadingInformation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    font-family: "Chivo", sans-serif !important;
}

.spinner {
    width: 120px;
    text-align: center;
}

.spinner > div {
    width: 15px;
    height: 15px;
    margin-left: 12px;
    background-color: #515B65;
    border-radius: 50%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0)
    }
    40% {
      -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
}
